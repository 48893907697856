<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <!-- <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3F4254">Order Management</h1>
    </div> -->

    <div class="pl-5 pr-5">
      <v-row>
        <v-col cols="9" class="p-0">
          <v-row>
            <!-- <v-col cols="4" class="d-flex align-items-center pb-0">
              <label class="text-body-1 font-weight-medium mb-6">Programme:</label>
              <v-select
                class="ml-2"
                label="select"
                v-model="programme_search_input"
                :items="programmes"
                item-text="name.en"
                item-value="name.en"
                solo
              ></v-select>
            </v-col> -->
            <!--            <v-col cols="4" class="d-flex align-items-center pb-0">-->
            <!--              <label class="text-body-1 font-weight-medium mb-6">Status:</label>-->
            <!--              <v-select-->
            <!--                class="ml-2"-->
            <!--                label="select"-->
            <!--                v-model="status_search_input"-->
            <!--                :items="status"-->
            <!--                item-text="label"-->
            <!--                item-value="value"-->
            <!--                solo-->
            <!--                dense-->
            <!--              ></v-select>-->
            <!--            </v-col>-->
            <!--            <v-col cols="4" class="d-flex align-items-center pb-0">-->
            <!--              <label class="text-body-1 font-weight-medium mb-6">Confirm Status:</label>-->
            <!--              <v-select-->
            <!--                class="ml-2"-->
            <!--                label="select"-->
            <!--                v-model="confirm_status_search_input"-->
            <!--                :items="confirm_status"-->
            <!--                item-text="label"-->
            <!--                item-value="value"-->
            <!--                solo-->
            <!--                dense-->
            <!--              ></v-select>-->
            <!--            </v-col>-->
            <v-col cols="12" class="p-0 m-0"></v-col>
            <v-col cols="10" class="d-flex align-items-center pt-0 pb-0">
              <div>
                <label class="text-body-1 font-weight-medium"
                  >Khoảng thời gian:</label
                >
              </div>
              <div class="d-flex ml-8">
                <label class="text-body-1 font-weight-medium mb-6 pt-4"
                  >Từ:</label
                >
                <date-picker
                  style=""
                  class="ml-2 mt-3"
                  value-type="format"
                  format="YYYY-MM-DD"
                  v-model="date_start_input"
                />
              </div>
              <v-icon class="ml-2 mr-2 mb-3">mdi-arrow-right</v-icon>
              <div class="d-flex">
                <label class="text-body-1 font-weight-medium mb-6 pt-4"
                  >Đến:</label
                >
                <date-picker
                  style=""
                  class="ml-2 mt-3"
                  value-type="format"
                  format="YYYY-MM-DD"
                  v-model="date_end_input"
                />
              </div>
            </v-col>
            <v-col cols="12" class="pt-0">
              <center>
                <v-btn
                  color="cyan"
                  class="ma-2 font-weight-bold"
                  outlined
                  large
                  @click="btnFilterData"
                >
                  <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                </v-btn>
                <v-btn
                  color="red"
                  class="ma-2 font-weight-bold"
                  outlined
                  large
                  @click="btnResetFilterData"
                >
                  <v-icon left>mdi-backup-restore</v-icon> Hủy lọc
                </v-btn>
              </center>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" class="p-0 position-relative">
          <div class="input-icon">
            <input
              type="text"
              class="form-control"
              placeholder="Search: order_code, user(phone)"
              v-model="search_input"
            />
            <span>
              <i class="flaticon2-search-1 text-muted"></i>
            </span>
          </div>
          <div class="position-absolute right-0 bottom-0">
            <v-btn
              color="#00BFA5"
              class="ma-2 font-weight-bold"
              outlined
              large
              @click="openDialogExport()"
            >
              <v-icon left>mdi-export-variant</v-icon> Export
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div>
        <v-row class="mt-4">
          <v-col cols="12" class="p-0">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Học sinh</th>
                    <th scope="col">Chương trình</th>
                    <th scope="col">
                      Ngày tạo đơn
                      <v-icon
                        color="white"
                        v-if="order_by_create_at === 'asc'"
                        @click="switchOrderCreate"
                        >mdi-arrow-up</v-icon
                      >
                      <v-icon
                        color="white"
                        v-if="order_by_create_at === 'desc'"
                        @click="switchOrderCreate"
                        >mdi-arrow-down</v-icon
                      >
                    </th>
                    <th scope="col">Thông tin Order</th>
                    <th scope="col">Price & Discount</th>
                    <th scope="col">Trạng thái</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(order, i) in orders">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p class="mb-0" v-if="order.user.name">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Name:
                          </span>
                          <span
                            class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                            >{{ order.user.name }}</span
                          >
                        </p>
                        <p class="mb-0" v-if="order.user.phone">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Phone:
                          </span>
                          <span
                            class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                            >{{ order.user.phone }}</span
                          >
                        </p>
                        <p class="mb-0" v-if="order.user.email">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Email:
                          </span>
                          <span
                            class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                            >{{ order.user.email }}</span
                          >
                        </p>
                      </td>
                      <td>
                        <template v-if="order.item.name">
                          <p
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            style="max-width: 250px"
                          >
                            {{ order.item.name.vi }}
                          </p>
                        </template>
                      </td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >{{
                            order.date_issued | convertTimestampToDMY_HMS
                          }}</span
                        >
                      </td>
                      <td>
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Code:
                          </span>
                          <span
                            class="text-dark-75 font-size-lg blue--text text--darken-1"
                            >{{ order.order_info }}</span
                          >
                        </p>
                        <p class="mb-0">
                          <!-- <span
                           class="label label-lg label-inline font-weight-bold py-4"
                           v-bind:class="(order.payment_method == 'Bank Transfer') ? 'label-light-warning' : 'label-light-success'">
                           {{ order.payment_method }}
                          </span> -->
                          <span
                            v-if="order.payment_method === 1"
                            class="label label-lg label-inline font-weight-bold py-4 label-light-success"
                            >OnePay</span
                          >
                          <span
                            v-else
                            class="label label-lg label-inline font-weight-bold py-4 label-light-warning"
                            >Chuyển khoản</span
                          >
                        </p>
                      </td>
                      <td>
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Fee:
                          </span>
                          <span
                            class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                            >{{ order.fee | formatNumber }}</span
                          >
                        </p>
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Discount:
                          </span>
                          <span
                            class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                            >{{ order.discount_amount | formatNumber }}</span
                          >
                        </p>
                        <p class="mb-0" v-if="order.coupon != null">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Coupon:
                          </span>
                          <span
                            class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                            >{{ order.coupon.code }}</span
                          >
                        </p>
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Fee after discount:
                          </span>
                          <span
                            class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                            >{{ order.fee_after_discount | formatNumber }}</span
                          >
                        </p>
                      </td>
                      <td>
                        <p class="mb-0">
                          <span
                            class="label label-lg label-inline font-weight-bold py-4 label-light-success"
                            v-if="order.status === 0"
                            >Chưa thanh toán</span
                          >
                          <span
                            class="label label-lg label-inline font-weight-bold py-4 label-light-primary"
                            v-if="order.status === 1"
                            >Đã thanh toán</span
                          >
                          <span
                            class="label label-lg label-inline font-weight-bold py-4 label-light-warning"
                            v-if="order.status === 2"
                            >{{
                              order.payment_method == "OnePay"
                                ? "Thanh toán không thành công"
                                : "Thanh toán thất bại"
                            }}</span
                          >
                          <span
                            class="label label-lg label-inline font-weight-bold py-4 label-light-danger"
                            v-if="order.status === 3"
                            >Đã hủy đơn</span
                          >
                        </p>
                        <template v-if="order.status !== 1">
                          <p class="mb-0" style="max-width: 300px">
                            <span
                              class="text-dark-75 font-weight-bolder font-size-lg"
                              >Reason:
                            </span>
                            <span
                              class="text-dark-75 text-muted font-size-lg"
                              >{{ order.response_message }}</span
                            >
                          </p>
                        </template>
                        <template
                          v-if="
                            order.payment_method === 'OnePay' &&
                            order.status === 1
                          "
                        >
                          <p class="mb-0">
                            <span
                              class="text-dark-75 font-weight-bolder font-size-lg"
                              >Confirmed at:
                            </span>
                            <span
                              class="text-dark-75 text-muted font-size-lg"
                              >{{ order.confirmed_at }}</span
                            >
                          </p>
                        </template>
                        <template
                          v-if="
                            order.payment_method === 'Bank Transfer' &&
                            order.status === 1
                          "
                        >
                          <p class="mb-0">
                            <span
                              class="text-dark-75 font-weight-bolder font-size-lg"
                              >Confirmed at:
                            </span>
                            <span
                              class="text-dark-75 text-muted font-size-lg"
                              >{{ order.confirmed_at }}</span
                            >
                          </p>
                          <p class="mb-0">
                            <span
                              class="text-dark-75 font-weight-bolder font-size-lg"
                              >Confirm user:
                            </span>
                            <span
                              class="text-dark-75 text-muted font-size-lg"
                              >{{ order.confirm_user }}</span
                            >
                          </p>
                        </template>
                      </td>
                      <td>
                        <template
                          v-if="
                            order.status === 0 && order.payment_method === 2
                          "
                        >
                          <div style="display: flex">
                            <v-tooltip left>
                              <template v-slot:activator="{ on, attrs }">
                                <button
                                  class="btn btn-icon btn-light-primary btn-sm"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="btnConfirmOrder(order.id)"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color="">mdi-check-bold</v-icon>
                                  </span>
                                </button>
                              </template>
                              <span>Confirm</span>
                            </v-tooltip>
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <button
                                  class="btn btn-icon btn-light-danger btn-sm ml-2"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="cancelOrder(order)"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color="">mdi-close-thick</v-icon>
                                  </span>
                                </button>
                              </template>
                              <span>Cancel</span>
                            </v-tooltip>
                          </div>
                        </template>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!orders.length">
                    <tr>
                      <td style="text-align: center" colspan="8">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogCancelOrder"
        max-width="1000px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Cancel Order</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogCancelOrder = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="4" class="d-flex pt-0">
                  <label class="text-body-1 font-weight-medium mb-6"
                    >Name:
                  </label>
                  <v-text-field
                    solo
                    class="ml-2"
                    readonly
                    required
                    v-model="name_user_input"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="d-flex pt-0">
                  <label class="text-body-1 font-weight-medium mb-6"
                    >Phone:
                  </label>
                  <v-text-field
                    solo
                    class="ml-2"
                    readonly
                    required
                    v-model="phone_user_input"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="d-flex pt-0">
                  <label class="text-body-1 font-weight-medium mb-6"
                    >Email:
                  </label>
                  <v-text-field
                    solo
                    class="ml-2"
                    readonly
                    required
                    v-model="email_user_input"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="p-0 m-0"></v-col>
                <v-col cols="7" class="d-flex pt-0">
                  <label class="text-body-1 font-weight-medium mb-6"
                    >Programme:
                  </label>
                  <v-text-field
                    solo
                    class="ml-2"
                    readonly
                    required
                    v-model="name_programme_input"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="p-0 m-0"></v-col>
                <v-col cols="4" class="d-flex pt-0">
                  <label class="text-body-1 font-weight-medium mb-6"
                    >Amount:
                  </label>
                  <span
                    class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg mt-1 ml-2"
                    >{{ amount_order_input | formatNumber }} VND</span
                  >
                </v-col>
                <v-col cols="4" class="d-flex pt-0">
                  <label class="text-body-1 font-weight-medium mb-6"
                    >Payment method:
                  </label>
                  <span
                    class="label label-lg label-inline font-weight-bold py-4 ml-2"
                    v-bind:class="
                      payment_method_input === 'Bank Transfer'
                        ? 'label-light-warning'
                        : 'label-light-success'
                    "
                  >
                    {{ payment_method_input }}
                  </span>
                </v-col>
                <v-col cols="4" class="d-flex pt-0">
                  <label class="text-body-1 font-weight-medium mb-6"
                    >Status:
                  </label>
                  <span
                    class="label label-lg label-inline font-weight-bold py-4 ml-2 label-light-success"
                    >Chưa thanh toán</span
                  >
                </v-col>
                <v-col cols="12" class="d-flex pt-0">
                  <label class="text-body-1 font-weight-medium mb-6"
                    >Cancel Reason:
                  </label>
                  <v-textarea
                    v-model="cancel_reason_input"
                    class="ml-2"
                    solo
                    label="Enter text"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnCancelOder"
            >
              Confirm
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogCancelOrder = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogExport" max-width="700px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Export dữ liệu</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogExport = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Phương thức thanh toán:</label
                      ></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        v-model="payment_method_export_input"
                        :items="list_payment_method"
                        item-value="value"
                        item-text="label"
                        dense
                        solo
                        multiple
                        chips
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Từ:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <date-picker
                        style=""
                        class="ml-2"
                        value-type="format"
                        format="YYYY-MM-DD"
                        v-model="date_start_export_input"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Đến:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <date-picker
                        style=""
                        class="ml-2"
                        value-type="format"
                        format="YYYY-MM-DD"
                        v-model="date_end_export_input"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnExportOrder()"
            >
              Export
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogExport = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import _ from "lodash";
export default {
  name: "ListOrder",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      orders: [],
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
      programmes: [],
      programme_search_input: "",
      status: [
        { label: "", value: "" },
        { label: "Chưa thanh toán", value: 0 },
        { label: "Đã thanh toán", value: 1 },
        { label: "Thanh toán thất bại", value: 2 },
        { label: "Đã hủy đơn", value: 3 },
      ],
      confirm_status: [
        { label: "", value: "" },
        { label: "Chưa xác nhận", value: 0 },
        { label: "Đã xác nhận", value: 1 },
      ],
      search_input: "",
      status_search_input: "",
      confirm_status_search_input: "",
      dialogCancelOrder: false,
      name_user_input: "",
      phone_user_input: "",
      email_user_input: "",
      name_programme_input: "",
      amount_order_input: 0,
      cancel_reason_input: "",
      id_order: null,
      payment_method_input: null,
      order_by_create_at: "desc",
      date_start_input: null,
      date_end_input: null,
      dialogExport: false,
      list_payment_method: [],
      payment_method_export_input: [],
      date_start_export_input: null,
      date_end_export_input: null,
    };
  },
  computed: {
    // filteredOrders() {
    //   return this.orders.filter(order => {
    //     return order.item.name.en.toLowerCase().includes(this.search_input.toLowerCase()) || order.user.name.toLowerCase().includes(this.search_input.toLowerCase()) || order.user.phone.includes(this.search_input) && order.item.name.en.toLowerCase().includes(this.programme_search_input.toLowerCase());
    //   })
    // }
  },
  watch: {
    programme_search_input(val) {
      this.search_input = val;
    },
    // status_search_input() {
    //   this.programme_search_input = '';
    //   this.getAllOrder(1);
    // },
    // confirm_status_search_input() {
    //   this.programme_search_input = '';
    //   this.getAllOrder(1);
    // },
    search_input: function (val) {
      this.actionSearch(val);
    },
    "data_pagination.current_page": function (page) {
      this.getAllOrder(page, this.search_input);
    },
    order_by_create_at: function () {
      this.getAllOrder(1, this.search_input);
    },
  },
  created() {
    this.is_call_api = true;
    this.resetDate();
    this.getAllPaymentMethod();
    this.getAllOrder();
    this.getAllProgramme();
    this.is_call_api = false;
  },
  methods: {
    async getAllPaymentMethod() {
      let vm = this;
      await ApiService.get("prep-app/order/list-payment-method").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.list_payment_method = response.data;
        }
      });
    },
    async getAllOrder(page = 1, query = "") {
      let vm = this;
      let url = "prep-app/order?page=" + page + "&query=" + query;
      let checkUrl = this.checkUrlListOrder(url);
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(checkUrl);
        if (res.status === 200) {
          vm.orders = res.data.data;
          vm.data_pagination = res.data.meta.pagination;
          vm.is_call_api = false;
        }
      } catch (e) {
        vm.is_call_api = false;
        console.log(e);
      }
    },
    checkUrlListOrder(url) {
      if (
        !(
          this.status_search_input === "" ||
          this.status_search_input === undefined ||
          this.status_search_input == null
        )
      ) {
        url = url + "&status=" + this.status_search_input;
      }
      if (
        !(
          this.confirm_status_search_input === "" ||
          this.confirm_status_search_input === undefined ||
          this.confirm_status_search_input == null
        )
      ) {
        url = url + "&is_confirmed=" + this.confirm_status_search_input;
      }
      if (
        !(this.date_start_input === null || this.date_start_input === undefined)
      ) {
        url = url + "&from_date=" + this.date_start_input;
      }
      if (
        !(this.date_end_input === null || this.date_end_input === undefined)
      ) {
        url = url + "&to_date=" + this.date_end_input;
      }
      url = url + "&order_by_create=" + this.order_by_create_at;
      return url;
    },
    async getAllProgramme() {
      let seft = this;
      await ApiService.get("prep-app/programme/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          // seft.programmes[0] = {};
          seft.programmes = response.data;
        }
      });
    },
    btnConfirmOrder(id_order) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to change?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!",
      }).then((result) => {
        if (result.value) {
          ApiService.put("prep-app/order/" + id_order + "/confirm")
            .then(function (res) {
              if (res.status === 202) {
                this.$toasted.success("Confirm Success!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllOrder();
              }
            })
            .catch(function (error) {
              if (error.response.status === 409) {
                let data_response_error =
                  error.response.data.error.message.split(".<br />");
                vm.showError(data_response_error);
              }
            });
        }
      });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    cancelOrder(order) {
      this.name_user_input = order.user.name;
      this.phone_user_input = order.user.phone;
      this.email_user_input = order.user.email;
      this.name_programme_input = order.item.name.en;
      this.amount_order_input = order.fee;
      this.payment_method_input = order.payment_method;
      this.cancel_reason_input = "";
      this.id_order = order.id;
      this.dialogCancelOrder = true;
    },
    btnCancelOder() {
      let vm = this;
      let data = {
        cancel_reason: this.cancel_reason_input,
      };
      Swal.fire({
        title: "Are you sure you want to cancel?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, cancel it!",
      }).then((result) => {
        if (result.value) {
          ApiService.put("prep-app/order/" + vm.id_order + "/cancel", data)
            .then(function (res) {
              if (res.status === 202) {
                this.$toasted.success("cancel Success!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllOrder();
              }
            })
            .catch(function (error) {
              if (error.response.status === 409) {
                let data_response_error =
                  error.response.data.error.message.split(".<br />");
                vm.showError(data_response_error);
              }
            });
        }
      });
    },
    actionSearch: _.debounce(function (val) {
      this.getAllOrder(1, val);
    }, 1200),
    switchOrderCreate() {
      let order_by = this.order_by_create_at;
      switch (order_by) {
        case "asc":
          this.order_by_create_at = "desc";
          break;
        case "desc":
          this.order_by_create_at = "asc";
          break;
        default:
          break;
      }
    },
    btnFilterData() {
      this.programme_search_input = "";
      this.getAllOrder(1, this.search_input);
    },
    btnResetFilterData() {
      this.date_start_input = null;
      this.date_end_input = null;
      this.status_search_input = "";
      this.confirm_status_search_input = "";
      this.programme_search_input = "";
      this.getAllOrder(1, this.search_input);
    },
    openDialogExport() {
      this.dialogExport = true;
      this.date_start_export_input = JSON.parse(
        JSON.stringify(this.date_start_input)
      );
      this.date_end_export_input = JSON.parse(
        JSON.stringify(this.date_end_input)
      );
      this.payment_method_export_input = [];
    },
    resetDate() {
      let currentDayOfMonth = new Date();
      let firstDayOfMonth = new Date(
        currentDayOfMonth.getFullYear(),
        currentDayOfMonth.getMonth(),
        1
      );
      this.date_start_input = this.convertOjDateToYMD(firstDayOfMonth);
      this.date_end_input = this.convertOjDateToYMD(currentDayOfMonth);
    },
    convertOjDateToYMD(oj_date) {
      let year = oj_date.getFullYear();
      let month = ("0" + (oj_date.getMonth() + 1)).slice(-2);
      let day = ("0" + oj_date.getDate()).slice(-2);
      return year + "-" + month + "-" + day;
    },
    btnExportOrder() {
      let vm = this;
      let data = {
        date_start: this.date_start_export_input,
        date_end: this.date_end_export_input,
        payment_methods: this.payment_method_export_input,
      };
      this.is_call_api = true;
      ApiService.post("prep-app/order/export", data)
        .then(function (res) {
          if (res.status === 200) {
            vm.is_call_api = false;
            vm.$toasted.success("Export thành công!!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            window.open(res.data, "_blank");
            vm.dialogExport = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.label.label-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
